
import React from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import Layout from '../../../components/layout'
import Nav from '../../../components/nav'
import SEO from '../../../components/seo'
import '../../../styles/index.scss'

const IndexPage = () => {
    return (
        <Layout lang="es">

            <SEO
                title="Optiwe - Sistema de CRM para WhatsApp y E-Commerce "
                description="Conecta a todos los equipos de tu e-commerce al CRM #1 para WhatsApp y mejora la comunicación entre los clientes y tu empresa."
                lang="es"
                locales={
                    [
                        { hreflang: 'es', href: 'https://optiwe.com/es/soluciones/e-commerce/' }
                    ]
                }
            />

            <main className="container-fluid ow-banner">
                {
                    /*
                    ^*********
                    * HEADER *
                    **********
                    */
                }
                <Nav lang="es" showButtons />

                {
                    /*
                    ***************
                    * MAIN BANNER *
                    ***************
                    * TODO: If the banner is used in another view, create a <Banner /> component
                    */
                }
                <div className="container ow-banner__content">
                    <div className="row">
                        <div className="col-xl-12 col-xxl-12 ow-banner__home-content">
                            <h1 className="ow-section--cards__title">
                                <span className="bold">Gestión de clientes por WhatsApp para <strong>E-COMMERCE</strong></span>
                            </h1>
                            <p className="ow-section--cards__desc">
                                Los clientes buscan comprar productos y servicios por los mismos canales que utilizan para comunicarse todos los días: WhatsApp, Instagram, Facebook, Twitter, etc. Si operas un e-commerce, es fundamental abrir canales de ventas digitales cómo WhatsApp. Con Optiwe, puedes crear una experiencia de ventas y atención al cliente omnicanal a partir de integrar WhatsApp a los procesos de venta de tu e-commerce.
                            </p>
                            <p className="ow-section--cards__desc">
                                Puedes agregar en WhatsApp, Instagram y otros canales, un chatbot que responde automáticamente las consultas de tus clientes sobre el catálogo de productos de tu empresa. El cliente puede chatear con el chatbot para agregar productos al carro de compras automáticamente y efectuar compras. También puedes configurar el sistema para vincular la conversación de WhatsApp con una sesión de compras en tu e-commerce y finalizar la venta en tu sitio web.
                            </p>
                            <p className="ow-section--cards__desc">
                                El chatbot de Optiwe permite identificar oportunidades de compra destacadas o abandonos en el carro de compra, para luego derivar la conversación a un agente de ventas que puede ayudar a cerrar la compra. También con el chatbot de Optiwe, puedes continuar vendiendo tus productos o servicios 24x7. Optiwe utiliza inteligencia artificial para entender las preguntas de tus clientes y mediante la información de la base de conocimientos de tus productos ofrece respuestas precisas que ayudan a cerrar más ventas.
                            </p>
                            <p className="ow-section--cards__desc">
                                Optiwe es una plataforma con APIs abiertas que te permiten automatizar flujos de tu e-commerce directamente en WhatsApp, para así mejorar la experiencia de compra y postventa de tus clientes. Puedes automatizar procesos dentro de WhatsApp cómo compra de productos, seguimiento de paquetes, cambio de productos, etc.
                            </p>
                            <p className="ow-section--cards__desc">
                                Al momento de derivar el cliente a un agente de atención, Optiwe realiza la asignación de manera rotativa para respetar los esquemas de comisión de ventas de tu empresa. El algoritmo de auto-asignación tiene en consideración que agentes de atención están online, offline o fuera de la oficina.
                            </p>
                            <p className="ow-section--cards__desc">
                                Con Optiwe, tus agentes de atención al cliente pueden chatear con los clientes directamente desde su teléfono móvil sin necesidad de utilizar su WhatsApp personal. Recibirán notificaciones en tiempo real cuando una nueva conversación esté disponible en su bandeja de entrada.
                            </p>
                            <p className="ow-section--cards__desc">
                                Todas las conversaciones quedan almacenadas y pueden ser monitoreadas desde un dashboard de control. Accede a reportes con información sobre cantidad de conversaciones atendidas por agentes de atención, tiempo de espera de tus clientes y más indicadores que te ayudarán a optimizar la atención al cliente.
                            </p>
                            <p className="ow-section--cards__desc">
                                También puedes utilizar el módulo de WhatsApp Marketing, para iniciar conversaciones con potenciales clientes de manera masiva y asignar dichas conversaciones a tu fuerza de ventas y aumentar la facturación de tu negocio.
                            </p>
                        </div>
                    </div>
                </div>
            </main>

        </Layout>
    )
}

export default IndexPage
